import styled from "styled-components"

import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useLogout } from "src/hooks/useLogout"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function OnboardingCurrentUser() {
  const { t, langKeys } = useTranslate()
  const { logout } = useLogout()
  const user = useGetUser()

  return (
    <Box>
      <MText variant="bodyS" color="secondary">{`${user.email}`}</MText> |
      <TextButton
        onClick={() =>
          logout({ reason: "User signed out from onboarding flow" })
        }
      >
        {t(langKeys.sign_out)}
      </TextButton>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  gap: ${spacing.XS2};
  place-items: center;
`
